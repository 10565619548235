export const environment = {
    apiUrl: "https://axerpazurefunctiontest.azurewebsites.net/",
    msalConfig: {
        auth: {
            clientId: 'e7dcc8a4-8839-4818-afba-869fc0c77114',
            authority: 'https://login.microsoftonline.com/8fabe025-b1db-4f0c-82ce-2f4a3ce2b985',
        },
    },
    apiConfig: {
        redirect: "https://test.axerp.axegaz.com/index.html",
        scopes: ['e7dcc8a4-8839-4818-afba-869fc0c77114/axerpui.read'],
        uri: "https://axerpazurefunctiontest.azurewebsites.net/"
    },
};